@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.hero-image {
  position: relative;
  width: 100%;
  height: 40vh;
  border-bottom-left-radius: var(--border-radius-xl);
  border-bottom-right-radius: var(--border-radius-xl);
  overflow: hidden;

  @include respond(lg) {
    height: 24rem;
    border-radius: var(--border-radius-xl);
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
 

  &__inner-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: var(--inner-shadow);
  }
}
