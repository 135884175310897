@import "../../../styles/abstracts/mixins";

.call-to-action {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: 1.25rem;
  margin-left: 2rem;
  margin-right: 2rem;
  background-color: var(--primary-50);
  border-radius: 1.5rem;
  padding: 1.5rem;

  @include respond(md) {
    width: 50%;
    align-self: center;
  }

  @include respond(lg) {
    width: auto;
    align-self: unset;
  }

  &__heading {
    align-self: center;
    color: var(--primary-800);
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    @include text-lg;
  }

  &__para {
    align-self: center;
    color: var(--primary-700);
    margin-bottom: 2rem;
    @include text-base;
  }
}
