@import "../../../styles/abstracts/mixins";

.button-tertiary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1.25rem;
  transition: all 0.2s ease-out;
  color: var(--blue-gray-500);
  background-color: transparent;
  border: 0;
  cursor: pointer;
  width: 100%;
  @include text-base;

  @include respond(md) {
    max-width: 9rem;
  }

  &:active {
    transition-duration: 0.2s;
    transform: scale(0.9);
  }
}