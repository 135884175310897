@import '../../../styles/abstracts/variables';
@import "../../../styles/abstracts/mixins";

.button-primary {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0.75rem;
  transition: all 0.2s ease-out;
  color: var(--white);
  background-color: var(--primary-500);
  border: 0;
  border-radius: var( --border-radius-base);
  cursor: pointer;
  @include text-base;

  @include respond(md) {
    max-width: 9rem;
  }

  &:hover {
    background-color: var(--primary-600);
  }

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}

.button-primary--full-width-on-mobile {
  width: 100%;

  @include respond(md) {
    max-width: 9rem;
  }
}
