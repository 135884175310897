@import "../../../styles/abstracts/mixins";
@import "../../../styles//abstracts/variables";

.search {
  display: flex;
  position: relative;
  background-color: var(--white);
  border-bottom: 0.125rem solid var(--blue-gray-100);
  padding: 0.75rem 1.2rem;
  z-index: 10;
  box-shadow: var(--shadow-md);

  @include respond(md) {
    height: 2.8rem;
  }

  @include respond(lg) {
    height: auto;
    padding: 0 1.2rem;
    box-shadow: unset;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke: var(--blue-gray-500);
    margin-right: 0.5rem;
  }

  &__select {
    max-width: 12rem;
    margin-left: auto;

    @include respond(lg) {
      margin-left: 0;
      padding: 0 0.75rem;
      border-right: solid 0.125rem var(--blue-gray-100);
    }
  }

  &__input {
    @include respond(lg) {
      margin-left: auto;
      padding: 0 0.75rem;
      border-left: solid 0.125rem var(--blue-gray-100);
      border-right: solid 0.125rem var(--blue-gray-100);
    }
  }

  &__button-group {
    display: none;
    
    @include respond(md) {
      display: flex;
      //margin-left: 2rem;
    }

    @include respond(lg) {
      height: auto;
      padding: 0.75rem 0;
  }
  }

  &__button {
    margin-right: 0.75rem;
  }
}
