@import '../../../styles/abstracts/variables';

.button-read-more {
  display: inline-flex;
  align-items: center;
  color: var(--primary-500);
  background-color: transparent;
  font-size: 1rem;
  border: 0;
  cursor: pointer;

  &__icon {
    margin-left: 0.5rem;
    stroke: var(--primary-500);
    height: 1.5rem;
    width: 1.5rem;
    margin-right: 0.5rem;
  }
}
