@import "../../../styles/abstracts/mixins";

.popular-section {
  display: flex;
  flex-direction: column;
  margin: 5rem 0;
  padding: 0 1.25rem;

  @include respond(lg) {
     margin: 4rem 0;
     padding: 0;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
  }

  &__pagination-buttons {
    display: none;

    @include respond(md) {
      display: block;
    }
  }

  &__secondary-heading {
    margin-right: auto;
  }

  &__cards {
    display: flex;
    flex-direction: column;

    @include respond(md) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1.5rem;
    }

    @include respond(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

    @include respond(xl) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

}
