@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";
@import "../../../styles/base/animations";

.toast {
  position: fixed;
  bottom: -5rem;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 0;

  @include respond(md) {
    display: flex;
    justify-content: center;
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 1rem 0.75rem;
    margin: 0 1.25rem;
    background-color: var(--primary-50);
    border: solid 0.125rem var(--primary-500);
    border-radius: 0.25rem;
    box-shadow: var(--shadow-xl);

    @include respond(md) {
      margin: 0;
    }
  }

  &__para {
    color: var(--primary-700);
    margin-left: 0.75rem;
    margin-right: auto;

    @include respond(md) {
      margin: 0 0.75rem;
    }
  }

  &__button {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    stroke: var(--primary-500);
  }
}


.toast--error {
  .toast__container {
    background-color: var(--red-50);
    border: solid 0.125rem var(--red-500);
  }
  .toast__para {
    color: var(--red-700);
    margin: 0 0.75rem;
  }

  .toast__icon {
    stroke: var(--red-500);
  }
}

.toast--success {
  .toast__container {
    background-color: var(--teal-50);
    border: solid 0.125rem var(--teal-500);
  }
  .toast__para {
    color: var(--teal-700);
    margin: 0 0.75rem;
  }

  .toast__icon {
    stroke: var(--teal-500);
  }
}

.toast--show {
  animation: toastSlideIn .5s forwards cubic-bezier(0, 0, 0.58, 1);
}

.toast--hide {
  animation: toastSlideOut .2s forwards cubic-bezier(0.42, 0, 1, 1);
}

