@import "../../../styles/base/animations";
@import "../../../styles/abstracts/mixins";

.header {
  position: relative;
  background-color: var(--white);

  @include respond(lg) {
    border-right: 0.125rem solid var(--blue-gray-100);
  }

  &__top-container {
    display: flex;
    align-items: center;
    background-color: var(--white);
    padding: 1.2rem;
    border-bottom: 0.125rem solid var(--blue-gray-100);

    @include respond(lg) {
      border-bottom: 0;
    }
  }

  &__menu-icon-container {
    margin-left: auto;
    @include respond(lg) {
      display: none;
    }
  }

  &__menu-icon {
    fill: var(--blue-gray-500);
    height: 2rem;
    width: 2rem;
  }
}
