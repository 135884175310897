@import "/src/styles/abstracts/mixins";
@import "../../../styles/base/animations";

.nav {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -62.5rem;
  height: calc(100vh - 8.125rem);
  width: 100vw;
  background-color: var(--white);
  transition: right .5s ease-in-out;

  @include respond(md) {
    height: calc(100vh - 9.25rem);
  }

  @include respond(lg) {
    position: relative;
    right: unset;
    height: 100%;
    width: auto;
    border-right: 0.125rem solid var(--blue-gray-100);
  }

  &--show {
    right:  0;
    z-index: 1000;
  }

  &__list {
    padding-top: 1rem;
  }

  &__item {
    padding: 0.75rem 0;
  }

  &__link,
  &__link--active {
    display: flex;
    align-items: center;
  }

  &__link {
    padding-left: 1.25rem;
    color: var(--blue-gray-500);
  }

  &__link--active {
    padding-left: 1rem;
    color: var(--primary-500);
    border-left: 0.25rem solid var(--primary-500);
  }

  &__link--active &__icon {
    stroke: var(--primary-500);
  }

  &__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    stroke: var(--blue-gray-500);
  }
}
