@import "../../../../styles/abstracts/mixins";
@import "../../../../styles/abstracts/variables";

.primary-heading {
  color: var(--blue-gray-800);
  margin-bottom: 0.5rem;
   @include text-3xl;

  @include respond(lg) {
    margin-bottom: 1rem;
    @include text-5xl;
  }
}

