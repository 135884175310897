@import '../../../styles/abstracts/variables';
@import '../../../styles/abstracts/mixins';

.card {
  border: 0.125rem solid var(--blue-gray-100);
  border-radius: var(--border-radius-xl);
  padding: 	1.25rem;
  background-color: var(--white);
  margin-bottom: 2.25rem;
  cursor: pointer;

  &__image-container {
    width: 100%;
    height: 16rem;
    margin-bottom: 2rem;

    @include respond(lg) {
      height: 12rem;
    }
  }
}
