@import "../../../styles/abstracts/mixins";

.hero-section {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  position: relative;
  cursor: pointer;

  &__score-container {
    position: absolute;
    right: 0;
    padding: 1.25rem;
    z-index: 1;
  }

  &__image-container {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  &__content {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1.25rem;
    z-index: 1;
  }

  &__content &__title {
    color: var(--white);
  }

   &__content &__tertiary-title {
    color: var(--blue-gray-50);
  }
 
  &__content &__button-primary {
    max-width: 9rem;
  }
}
