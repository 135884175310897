// Note: using px because media queries work in a slightly different way and some of the browsers,
// will bug out using rem in media queries.

@mixin respond($breakpoint) {
  @if $breakpoint == sm {
    @media only screen and (min-width: 640px) {
      @content;
    }
  }
  @if $breakpoint == md {
    @media only screen and (min-width: 768px) {
      @content;
    }
  }
  @if $breakpoint == lg {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  }
  @if $breakpoint == xl {
    @media only screen and (min-width: 1280px) {
      @content;
    }
  }
  @if $breakpoint == 2xl {
    @media only screen and (min-width: 1536px) {
      @content;
    }
  }
}

// Fonts
@mixin text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@mixin text-base {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

@mixin text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 400;
}

@mixin text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 400;
}

@mixin text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
}

@mixin text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 800;
}

@mixin text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 800;
}

@mixin text-5xl {
  font-size: 3rem;
  line-height: 1;
  font-weight: 800;
}
