@import "../../../styles/abstracts/variables";
@import "../../../styles/abstracts/mixins";

.movie-section {
  margin: 1.5rem 2rem;
  @include respond(lg) {
    margin: 0;
  }

  &__container {
    @include respond(lg) {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  &__image-container {
    position: relative;
    border-radius: 1rem;
    height: 12rem;
    padding-top: 1.25rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 2.75rem;

    @include respond(md) {
      height: 20rem;
    }

    @include respond(lg) {
      flex: 1;
    }
  }

  &__button-favorites-container {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  &__genre-container {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    margin-bottom: 1.5rem;
  }

  &__para-container {
    margin-bottom: 2.25rem;
  }

  &__genre-container .movie__score {
    margin: 0;
  }

  &__button-icon {
    height: 1.5rem;
    width: 1.5rem;
    stroke: var(--white);
  }

  &__content {
    margin-bottom: 2rem;
    
     @include respond(lg) {
      flex: 1;
      padding-top: 1.25rem;
      padding-right: 1.25rem;
      margin-bottom: 4rem;
    }
  }

  &__divider {
    background-color: var(--blue-gray-100);
    height: 0.125rem;
    width: 100%;
    margin-bottom: 2rem;

    @include respond(lg) {
      margin-bottom: 6rem;
    }
  }
}
