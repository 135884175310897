@import "../abstracts/mixins";

*, 
*::after,
*::before{
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html{
    font-size: 16px;
    color: var(--blue-gray-500);
    box-sizing: border-box;
}

body {
   background-color: var(--background);
   font-family: 'Roboto';
   overflow-x: hidden;

   @include respond(lg) {
     overflow: hidden
   }
}

textarea:focus, input:focus{
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

button:active {
    transition-duration: 0.2s;
    transform: scale(0.9);
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}
