@import "../../../styles/abstracts/mixins";

.select {
  display: flex;
  width: 100%;
  @include text-base;
  color: var(--blue-gray-500);

  &__select {
    flex: 1;
    color: var(--blue-gray-500);
    width: 100%;
    border: 0;
    background: url('../../../icons/down-chevron.svg') no-repeat right;
    -webkit-appearance: none;
    background-position-x: 98%;
    @include text-base;
  }

  &__icon {
    pointer-events: unset;
    width: 2rem;
    height: 2rem;
    stroke: var(--blue-gray-500);
  }

  &__option {
    padding: 0.5rem 0.5rem;
    @include text-base;
    color: var(--blue-gray-500);
  }
}
