@import "../../../styles/abstracts/variables";

.standard-image {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-xl);
  overflow: hidden;

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__inner-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    box-shadow: var(--inner-shadow);
  }
}
