@import "/src/styles/abstracts/mixins";

.layout {
  @include respond(lg) {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: 4.5rem 1fr;
    width: 100vw;
    height: 100vh;
  }

  &__header {
    @include respond(lg) {
      grid-column: 1/2;
      grid-row: 1/2;
    }
  }

  &__search {
    @include respond(lg) {
      grid-column: 2/3;
      grid-row: 1/2;
    }
  }

  &__nav {
    @include respond(lg) {
      grid-column: 1/2;
      grid-row: 2/3;
    }
  }

  &__main {
     @include respond(lg) {
       overflow-y: scroll;
    }
  }

  &__wrapper {
    max-width: 80.75rem;
    // overflow-y: scroll;
    
    @include respond(lg) {
      max-width: 80rem;
      padding: 2.5rem;
    }
  }
}
