:root {
  // Whites
  --white: #ffffff;

  // Grays
  --blue-gray-900: hsl(209, 61%, 16%);
  --blue-gray-800: hsl(211, 39%, 23%);
  --blue-gray-700: hsl(209, 34%, 30%);
  --blue-gray-600: hsl(209, 28%, 39%);
  --blue-gray-500: hsl(210, 22%, 49%);
  --blue-gray-400: hsl(209, 23%, 60%);
  --blue-gray-300: hsl(211, 27%, 70%);
  --blue-gray-200: hsl(210, 31%, 80%);
  --blue-gray-100: hsl(212, 33%, 89%);
  --blue-gray-50: hsl(210, 36%, 96%);

  // Primary
  --primary-900: hsl(205, 100%, 21%);
  --primary-800: hsl(205, 87%, 29%);
  --primary-700: hsl(205, 82%, 33%);
  --primary-600: hsl(205, 76%, 39%);
  --primary-500: hsl(205, 67%, 45%);
  --primary-400: hsl(205, 65%, 55%);
  --primary-300: hsl(205, 74%, 65%);
  --primary-200: hsl(205, 84%, 74%);
  --primary-100: hsl(205, 97%, 85%);
  --primary-50: hsl(205, 79%, 92%);

  // background
  --background: hsl(210, 40%, 98%);

  // Teals
  --teal-900: hsl(176, 100%, 13%);
  --teal-800: hsl(172, 98%, 20%);
  --teal-700: hsl(172, 94%, 26%);
  --teal-600: hsl(170, 91%, 32%);
  --teal-500: hsl(168, 78%, 41%);
  --teal-400: hsl(166, 64%, 49%);
  --teal-300: hsl(164, 70%, 63%);
  --teal-200: hsl(162, 73%, 74%);
  --teal-100: hsl(163, 75%, 87%);
  --teal-50: hsl(165, 67%, 96%);

  // Reds
  --red-900: hsl(360, 92%, 20%);
  --red-800: hsl(360, 85%, 25%);
  --red-700: hsl(360, 79%, 32%);
  --red-600: hsl(360, 72%, 38%);
  --red-500: hsl(360, 67%, 44%);
  --red-400: hsl(360, 64%, 55%);
  --red-300: hsl(360, 71%, 66%);
  --red-200: hsl(360, 77%, 78%);
  --red-100: hsl(360, 82%, 89%);
  --red-50: hsl(360, 100%, 97%);

  // Yellows
  --yellow-900: hsl(43, 86%, 17%);
  --yellow-800: hsl(43, 77%, 27%);
  --yellow-700: hsl(43, 72%, 37%);
  --yellow-600: hsl(42, 63%, 48%);
  --yellow-500: hsl(42, 78%, 60%);
  --yellow-400: hsl(43, 89%, 70%);
  --yellow-300: hsl(43, 90%, 76%);
  --yellow-200: hsl(45, 86%, 81%);
  --yellow-100: hsl(45, 90%, 88%);
  --yellow-50: hsl(45, 100%, 96%);

  // border radius
  --border-radius-base: 0.25rem;
  --border-radius-xl: 1.25rem;

  // border radius
  --inner-shadow: inset 0 0.25rem 0.25rem rgba(0, 0, 0, 0.25);

  // Drop shadows
  --shadow-xs: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  --shadow-sm: 0 0.375rem 0.75rem rgba(0, 0, 0, 0.1);
  --shadow-md: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  --shadow-lg: 0 0.625rem 1.25rem rgba(0, 0, 0, 0.1);
  --shadow-xl: 0 0.75rem 1.5rem rgba(0, 0, 0, 0.1);
}
